/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import * as React from 'react';
import {DotNavigation, DotNavigationTheme} from 'wix-ui-tpa/cssVars';
import s from '../Carousel.scss';
import {classes as dotsStylable} from './CarouselMobile.st.css';
import sm from './CarouselMobile.scss';
import classNames from 'classnames';

interface CarouselMobileProps {
  slidesCount: number;
  activeSlide: number;
  slideTo: (index: number) => void;
  children?: React.ReactChild[] | React.ReactChild;
}

export enum MobileCarouselDataHooks {
  MobileCarousel = 'carousel-mobile',
  MobileCarouselContainer = 'carousel-mobile-container',
  Dots = 'carousel-mobile-dots',
}

export const CarouselMobile: React.FC<CarouselMobileProps> = (props: CarouselMobileProps) => {
  const {activeSlide, slidesCount, slideTo} = props;

  const stopPropagation = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const carouselClasses = classNames(s.carousel, {[sm.msTouch]: navigator.maxTouchPoints});
  const dotsClasses = classNames(dotsStylable.root, sm.dots);

  return (
    <div className={s.carouselWrapper} data-hook={MobileCarouselDataHooks.MobileCarouselContainer}>
      <div className={carouselClasses} data-hook={MobileCarouselDataHooks.MobileCarousel}>
        {props.children}
      </div>
      <div role="navigation" className={dotsClasses} onClick={stopPropagation}>
        <DotNavigation
          aria-label={activeSlide?.toString()}
          data-hook={MobileCarouselDataHooks.Dots}
          theme={DotNavigationTheme.Dark}
          currentIndex={activeSlide || 0}
          length={slidesCount}
          onSelect={(index: number) => void slideTo(index)}
        />
      </div>
    </div>
  );
};
